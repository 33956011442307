<template>
  <modal-dialog :config="config">
    <page-section :margin-top="'xs'">
      <centered-column>
        <font-awesome-icon :icon="iconBookmark" class="text-4xl text-white"></font-awesome-icon>
      </centered-column>
    </page-section>

    <page-section :margin-top="'xs'">
      <standard-heading :center="true" :size="2">Add New Bookmark</standard-heading>
    </page-section>

    <page-section :margin-top="'2xs'">
      <centered-column v-if="destinationGroupMenuItems.length > 0">
        <div class="text-fl-lg text-light text-center mb-4">
          Add <b>{{ contentEntry.title }}</b> to a bookmark group:
        </div>
        <repel :wrap="false" class="w-full max-w-xl">
          <dropdown-menu
            class="w-full py-fl-sm px-fl-md bg-darkest border border-white rounded-sm text-white text-fl-lg"
            :items="destinationGroupMenuItems"
            :on-change="onDestinationGroupSelected"
            title="Add Bookmark group"
          />
          <icon-button
            :on-click="onAddGroup"
            :icon="iconPlus"
            :tooltip="'Add Bookmark Group'"
            type="button"
          />
        </repel>
      </centered-column>
      <centered-column v-else>
        <div class="text-light text-center mb-4">
          Add <b>{{ contentEntry.title }}</b> to my default bookmark group
        </div>
      </centered-column>
    </page-section>
  </modal-dialog>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, Ref, ref, watch} from 'vue';
import DropdownMenu from '../../../generic/dropdown-menu/DropdownMenu.vue';
import {DropdownMenuItem} from '../../../generic/dropdown-menu/types';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Repel from '../../../core/compositions/Repel.vue';
import CenteredColumn from '../../../core/compositions/CenteredColumn.vue';
import IconButton from '../../../core/button/IconButton.vue';
import ModalDialog from '../../../generic/modal-dialog/ModalDialog.vue';
import PageSection from '../../../core/page/PageSection.vue';
import {BookmarkNode} from '../types';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {ModalDialogConfig} from '../../../vue-composition/modal-dialog/types';
import {AddBookmarkDialogData} from '../../../vue-composition/bookmark/types';
import {faBookmark} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    DropdownMenu,
    ModalDialog,
    StandardHeading,
    Repel,
    PageSection,
    CenteredColumn,
    IconButton,
    FontAwesomeIcon
  },
  props: {
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    groups: {type: Object as PropType<Ref<ReadonlyArray<BookmarkNode>>>, required: true},
    reload: {type: Function as PropType<() => Promise<void>>, required: true},
    addBookmark: {type: Function as PropType<(name: string) => Promise<void>>, required: true}
  },
  setup(props) {
    const destinationGroupMenuItems = computed(() => {
      return props.groups.value.map(g => {
        const id = g.bookmark ? g.bookmark.id : undefined;
        return {
          value: id,
          text: g.title === undefined ? 'Default' : g.title,
          selected: g.selected
        };
      });
    });
    const destinationGroup = ref(props.groups.value[0] as Readonly<BookmarkNode>);

    const onDestinationGroupSelected = (item: Readonly<DropdownMenuItem>) => {
      const group = props.groups.value.find(g => {
        if (g.bookmark === undefined) {
          return item.value === undefined;
        }
        return g.bookmark.id === item.value;
      });
      if (group !== undefined) {
        destinationGroup.value = group;
      }
    };

    const updateReturnData = () => {
      const data: AddBookmarkDialogData = {
        contentEntry: props.contentEntry,
        group: destinationGroup.value
      };
      props.config.setData(data);
    };

    // Update the return data from the dialog when the group changes.
    watch(destinationGroup, _ => {
      updateReturnData();
    });
    updateReturnData();

    const onAddGroup = async () => {
      const name = prompt('Please enter a name for the new bookmark group!', 'Untitled');
      if (name !== null) {
        const id = await props.addBookmark(name);
        const group = props.groups.value.find(g => {
          if (g.bookmark !== undefined) {
            return g.bookmark.id === id;
          }
          return false;
        });
        if (group !== undefined) {
          destinationGroup.value = group;
        }
      }
    };

    const iconBookmark = computed(() => faBookmark);
    const iconPlus = computed(() => faPlus);

    return {
      iconBookmark,
      iconPlus,
      destinationGroup,
      destinationGroupMenuItems,
      onDestinationGroupSelected,
      onAddGroup
    };
  }
});
</script>
