<template>
  <content-column :width="'wide'">
    <repel :gap="'gap-x-fl-xl gap-y-fl-md'" class="py-fl-sm bg-black/90 backdrop-blur-sm">
      <!-- Filter button, filter property count, filter property pills -->
      <cluster :wrap="true" :gap="'gap-x-fl-lg gap-y-fl-sm'">
        <!-- Filter button + filter property count -->
        <cluster :gap="'gap-x-fl-xs'">
          <link-icon-button
            :icon="iconFilter"
            :label="'Filters'"
            :tooltip="'Display filter options'"
            :on-click="collection.showFilterSidebar"
            :color="'lightest'"
            class="text-base font-semibold"
          />
          <count v-if="numberOfSelectedProperties > 0" class="bg-primary">
            <div class="text-xs text-black font-semibold">
              {{ numberOfSelectedProperties }}
            </div>
          </count>
        </cluster>

        <!-- Filter property pills -->
        <cluster
          v-if="deviceType.largerThan('narrow')"
          :wrap="true"
          :gap="'gap-x-fl-xs gap-y-fl-xs'"
        >
          <filter-set-prop-button
            v-for="prop in allSelectedProperties"
            :key="prop.property"
            :filter-set="collection.filterSet"
            :property="prop"
          />
        </cluster>
      </cluster>

      <!-- Grid/List switcher, Sort order switcher -->
      <cluster :gap="'gap-x-fl-lg gap-y-fl-sm'">
        <loading-icon class="text-mid" :label="'Loading'" />
        <content-entry-index-type-switcher />
        <link-icon-button
          :icon="iconUpDown"
          :on-click="collection.sort.next"
          :label="collection.sort.configLabel.value"
          :tooltip="'Change sort order'"
        />
      </cluster>
    </repel>
  </content-column>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import Count from '../../../generic/count/Count.vue';
import FilterSetPropButton from '../../../generic/filter-set-filter/FilterSetPropButton.vue';
import ContentEntryIndexTypeSwitcher from '../../pages/partials/ContentEntryIndexTypeSwitcher.vue';
import LoadingIcon from '../../../generic/loader/LoadingIcon.vue';
import {faArrowUpArrowDown} from '@fortawesome/pro-regular-svg-icons/faArrowUpArrowDown';
import {faFilterList} from '@fortawesome/pro-regular-svg-icons/faFilterList';
import {CollectionComposition} from './collection/types';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    ContentColumn,
    Repel,
    Cluster,
    LinkIconButton,
    Count,
    FilterSetPropButton,
    LoadingIcon,
    ContentEntryIndexTypeSwitcher
  },

  props: {
    collection: {type: Object as PropType<Readonly<CollectionComposition>>, required: true}
  },

  setup(props) {
    const iconFilter = computed(() => faFilterList);
    const iconUpDown = computed(() => faArrowUpArrowDown);
    const deviceType = useDeviceType();

    const allSelectedProperties = computed(() => {
      return props.collection.filterSet.allSelectedProperties.value;
    });

    const numberOfSelectedProperties = computed(() => {
      return props.collection.filterSet.numberOfSelectedProperties.value;
    });

    return {
      iconFilter,
      iconUpDown,
      allSelectedProperties,
      numberOfSelectedProperties,
      deviceType
    };
  }
});
</script>
