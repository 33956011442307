<template>
  <flow :gap="4">
    <div class="relative h-fl-xs w-full" :aria-label="ariaLabel">
      <div
        class="absolute top-0 left-0 h-fl-xs rounded-sm rounded-full bg-dark w-full"
        aria-hidden="true"
      >
        <div
          :class="`absolute top-0 left-0 h-fl-xs rounded-sm rounded-full ${bgColorClass}`"
          :style="progressWidth"
        ></div>
      </div>
    </div>
    <repel class="font-semibold text-fl-sm">
      <div v-if="label.length" aria-hidden="true" class="uppercase text-mid">
        {{ label }}
      </div>
      <div v-if="progress > 0" aria-hidden="true" :class="`${textColorClass}`">
        {{ `${progressText}%` }}
      </div>
    </repel>
  </flow>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {Progress} from '../../../backend/progress/progress-types';
import Repel from '../../core/compositions/Repel.vue';
import Flow from '../../core/compositions/Flow.vue';
import {ColorConfig} from '../../core/color-config';

export default defineComponent({
  components: {
    Repel,
    Flow
  },
  props: {
    progress: {type: Number as PropType<Progress>, required: true},
    label: {type: String, default: ''},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const ariaLabel = computed(() => {
      return `${props.label.length ? props.label + ', ' : 'Progress'} ${props.progress}%`;
    });
    const progressWidth = computed(() => {
      if (props.progress > 100) {
        throw new Error('Progress bar: progress > 100');
      }
      return `width: ${props.progress}%`;
    });
    const bgColorClass = computed(() => {
      if (props.spotColor !== undefined) {
        return props.spotColor.bg;
      }
      return 'bg-progress';
    });
    const textColorClass = computed(() => {
      if (props.spotColor !== undefined) {
        return props.spotColor.text;
      }
      return 'text-progress';
    });
    const progressText = computed(() => {
      return Math.floor(props.progress);
    });
    return {
      ariaLabel,
      progressWidth,
      bgColorClass,
      textColorClass,
      progressText
    };
  }
});
</script>
