<template>
  <!-- ??? Fix the v-model problem below. -->
  <input
    v-model="search.searchTerm.value"
    class="w-full px-fl-md py-fl-sm text-fl-lg text-mid placeholder:text-mid rounded-sm border border-mid bg-transparent"
    type="search"
    :placeholder="placeholderText"
    :aria-label="'Search titles'"
    :title="'Search titles'"
  />
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ContentEntrySearchComposition} from '../../vue-composition/search/search';

export default defineComponent({
  components: {},
  props: {
    placeholderText: {type: String, default: ''},
    search: {type: Object as PropType<ContentEntrySearchComposition>, required: true}
  },
  setup() {
    return {};
  }
});
</script>

<style scoped>
.search {
  font-size: var(--size-action);
  border-radius: var(--radius-xs);
  background: transparent;
  color: var(--color-mid);
  border: 1px solid var(--color-mid-deep);
  width: 100%;
}
</style>
