<template>
  <margins>
    <content-area :area="titleArea">
      <standard-heading
        v-if="titleImage === undefined"
        :size="1"
        :spot-color="spotColor"
        :center="centerHeading"
      >
        {{ sectionTitle }}
      </standard-heading>
      <div v-else class="flex justify-center">
        <imgix-image
          :config="titleImage"
          :class="`w-full ${titleImageMaxHeight} ${titleImageMaxWidth}`"
        />
      </div>
    </content-area>
    <content-area
      v-if="infoButton && tooltip"
      :area="infoButtonArea"
      class="flex justify-end items-start"
    >
      <icon-button
        :size="iconSize"
        :color="'dark'"
        :icon="icon"
        :tooltip="tooltip"
        :on-click="onInfoButtonClicked"
      />
    </content-area>
  </margins>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, ref} from 'vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import {useLoader} from '../../../vue-composition/loader/loader';
import {getProductSectionWithHandle} from '../../../../backend/product/product-query';
import IconButton from '../../../core/button/IconButton.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {deviceTypeSwitch, useDeviceType} from '../../../vue-composition/device-type/device-type';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {ColorConfig} from '../../../core/color-config';
import {IconButtonSize} from '../../../core/button/implementation/utils';
import {titleAreaNoInfoButton, titleAreaWithInfoButton} from './utils';
import {ImgixImageConfig} from '../../../core/imgix-image/types';

/**
 * Content entry section header.
 *
 * This component should be used for pages that represent an entire content
 * section, such as landing pages (e.g., Mentors, Learning Pathways, or Players
 * Path).
 *
 * It has the ability to present an info button that can be used to display
 * a landing page section (see /src/components/landing-page).
 *
 * NOTE: If your page has an index of content entries (e.g., a card grid
 * or a list), you may want to use the ContentEntryIndexPage component
 * in /src/components/content-entry/pages.
 */
export default defineComponent({
  components: {
    ImgixImage,
    Margins,
    ContentArea,
    StandardHeading,
    IconButton
  },
  props: {
    center: {type: Boolean, default: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},

    // If provided, fetch the name of this section from Craft and use that as the title.
    sectionHandle: {type: String, default: undefined},
    // Section header title; can optionally be overridden by an image. (See below.)
    title: {type: String, default: undefined},
    // Optional width of the header title.
    titleWidth: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    // URL of optional image that replaces the title (e.g., learning pathways logotype)
    titleImage: {type: Object as PropType<ImgixImageConfig>, default: undefined},
    // Optional max height of title image
    titleImageMaxHeight: {type: String, default: ''},
    // Optional max height of title image
    titleImageMaxWidth: {type: String, default: ''},
    // If true, display an info button.
    infoButton: {type: Boolean, default: false},
    // Called when info button is clicked.
    onInfoButtonClicked: {type: Function as PropType<() => void>, default: undefined},
    // Icon to use for the info button.
    infoButtonIcon: {type: Object as PropType<IconDefinition>, default: undefined},
    // Tooltip to use for the info button.
    infoButtonTooltip: {type: String, default: undefined},

    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const loader = useLoader();
    const sectionTitle = ref(props.title);
    const centerHeading = computed(() => (center.value ? true : false));
    const deviceType = useDeviceType();
    const center = computed(() => (deviceType.largerThan('narrow') ? props.center : false));

    const titleArea = computed(() => {
      const screen = deviceType.screen.value;
      if (props.infoButton) {
        return titleAreaWithInfoButton(props.width, screen, props.center);
      }
      return titleAreaNoInfoButton(props.width, screen, props.center);
    });

    const infoButtonArea = computed(() => {
      const screen = deviceType.screen.value;
      if (screen === 'narrow') {
        return {start: 4, span: 1};
      }
      return {start: 12, span: 1};
    });

    onMounted(async () => {
      loader.setLoading(true);

      if (props.sectionHandle) {
        const section = await getProductSectionWithHandle(props.sectionHandle);
        if (section !== undefined) {
          sectionTitle.value = section.title;
        } else {
          throw new Error(`${props.sectionHandle} is not a product section`);
        }
      }

      loader.setLoading(false);
    });

    const icon = computed(() => (props.infoButtonIcon ? props.infoButtonIcon : faQuestionCircle));
    const tooltip = computed(() =>
      props.infoButtonTooltip ? props.infoButtonTooltip : 'Show information about this Section'
    );
    const iconSize = deviceTypeSwitch<IconButtonSize>({
      narrow: 'xl',
      sm: '2xl'
    });

    return {
      centerHeading,
      sectionTitle,
      icon,
      iconSize,
      tooltip,
      titleArea,
      infoButtonArea
    };
  }
});
</script>
