<template>
  <div v-if="tutor !== undefined" :id="tutor.slug">
    <card :url="url">
      <template #hero>
        <card-hero-image v-if="image !== undefined" :config="image" />
        <card-hero-shimmer v-else :aspect="'aspect-3/4'" />
      </template>
      <template #body>
        <div class="pt-fl-2xs">
          <div class="w-full font-semibold text-light text-base">
            {{ tutor.title }}
          </div>
          <div v-if="bio !== undefined" class="text-mid text-sm">
            {{ bio }}
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import Card from '../../generic/card/Card.vue';
import CardHeroImage from '../../generic/card/partials/hero/CardHeroImage.vue';
import CardHeroShimmer from '../../generic/card/partials/hero/CardHeroShimmer.vue';
import {Tutor} from '../../../backend/tutor/tutor-types';
import {getImageConfig} from '../../../backend/content/content-entry/content-entry-query-utils';
import {ImgixImageWidth} from '../../core/imgix-image/types';

export default {
  components: {
    Card,
    CardHeroImage,
    CardHeroShimmer
  },
  props: {
    tutor: {type: Object as PropType<Readonly<Tutor>>, default: undefined},
    imageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    displayBio: {type: Boolean, default: true},
    displayMentorFocusOfStudy: {type: Boolean, default: true}
  },
  setup(props) {
    const url = computed(() => {
      if (props.tutor !== undefined) {
        return props.tutor.url;
      }
      return undefined;
    });
    const image = computed(() => {
      if (props.tutor === undefined) {
        return undefined;
      }
      return getImageConfig(
        props.tutor.imageCollection,
        ['profile', 'avatar'],
        props.tutor.title,
        props.imageWidth,
        {ar: '3:4', fit: 'crop'}
      );
    });
    let bio = computed(() => {
      if (props.tutor === undefined) {
        return undefined;
      }
      if (props.displayBio) {
        if (
          props.displayMentorFocusOfStudy &&
          props.tutor.tutorFocusOfStudy !== undefined &&
          props.tutor.tutorFocusOfStudy !== null &&
          props.tutor.tutorFocusOfStudy.length > 0
        ) {
          return props.tutor.tutorFocusOfStudy;
        }
        return props.tutor.tutorShortBiography;
      }
      return undefined;
    });

    return {
      url,
      image,
      bio
    };
  }
};
</script>
