<template>
  <div class="flex flex-col justify-center w-full py-fl-2xs">
    <div v-if="progress && progress < 100" class="relative h-fl-3xs w-full rounded-sm bg-dark">
      <div
        :class="`relative top-0 left-0 h-fl-3xs rounded-sm rounded-full ${color}`"
        :style="`width: ${progress}%`"
      ></div>
    </div>
    <div v-else class="h-fl-3xs"></div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ColorConfig} from '../../../core/color-config';

export default defineComponent({
  props: {
    progress: {type: Number, default: undefined},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const color = computed(() => {
      if (props.spotColor !== undefined) {
        return props.spotColor.bg;
      }
      return 'bg-progress';
    });
    return {color};
  }
});
</script>
